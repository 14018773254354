import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import LazyLoad from 'react-lazyload';

import RenderPostWrapper from 'components/HOC/RenderPostWrapper';
import AirfieldInfo from 'components/airfield/AirfieldInfo'
import Button from 'components/buttons/button';
import InternalLink from 'components/links/internalLink';

import { H1, Para } from 'styled';

import replaceLocalePhoneNumber from 'utils/functions/replaceLocalePhoneNumber';
import replaceVariablesFromPrismic from 'utils/functions/replaceVariablesFromPrismic';
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const LoadableSingleMarkerMap = loadable(() => import( /* webpackChunkName: "gatsby-SingleMarkerMap" */ 'components/maps/SingleMarkerMap'));

const GenericAirfield = ({ data, pageContext, location, preview}) => {
  const airfieldDetails = pageContext ? pageContext.airfield_data.node : null;
  const fsExcludeFlag = airfieldDetails.fs_exclude_flag;

  useEffect(() => {
    localStorage.setItem("excludeFS", fsExcludeFlag)
  }, [])

  const language = pageContext.lang;

  const alternateLanguages = pageContext.alternateLanguages;

  const filterLocaleAirfieldProps = filterPrismicDataByLanguage(data.allPrismicPropertiesAirfield.edges, language)
  
  const localeAirfieldProps = filterLocaleAirfieldProps.node.data;
  const buttonURL = `${localeAirfieldProps.url_to_destinations_index_page}${process.env.GATSBY_URL_SUFFIX}`

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  //Hero Image default banner image
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;

  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  const airfieldLocationData = {
    cityName: airfieldDetails.city_name,
    countryName: airfieldDetails.country_name,
    latitude: airfieldDetails.latitude,
    longitude: airfieldDetails.longitude,
    iata_code:airfieldDetails.iata_code,
    icao_code:airfieldDetails.icao_code,
    time_zone:airfieldDetails.time_zone,
  }

  const phoneNumber = replaceLocalePhoneNumber(data.allPrismicUniversalPhoneNumber.edges);

  const airfieldName = airfieldDetails.airfield_name
    .split(" ")
    .map(el => el.toLowerCase().charAt(0).toUpperCase() + el.toLowerCase().substring(1))
    .join(" ");

  const heading = localeAirfieldProps.generic_airfield_title
    .replace('{{airfield}}', `${airfieldName}`);

  const contactUsParagraph = localeAirfieldProps.generic_contact_us_paragraph.text
    .replace('{{airfield}}', airfieldName)
    .replace('{{telephone}}', phoneNumber);

  const replacedData = replaceVariablesFromPrismic(localeAirfieldProps, phoneNumber, airfieldName, airfieldDetails.country_name, airfieldDetails.city_name);

  const headData = {
    meta_title: replacedData.title,
    meta_description: replacedData.description,
    meta_keywords: replacedData.keywords,
    og_title: replacedData.title,
    og_description: replacedData.description,
    og_type: 'website',
    og_image: defaultBannerImage.url.split('?')[0],
  }

  // press release properties for sidebar widget
  const genericAirfieldProperties = !preview && filterPrismicDataByLanguage(data.allPrismicPropertiesAirfield.edges, language);

  const hasWidget = preview ? data?.allPrismicWidgetData?.edges?.length > 0 
  : genericAirfieldProperties?.node?.data?.body?.length > 0;

  const widgetData = preview ? data?.allPrismicWidgetData?.edges : genericAirfieldProperties?.node?.data?.body;

  return (
    <RenderPostWrapper 
      showAddress={true} 
      airfieldLocationData={airfieldLocationData}
      meta={headData}
      location={location}
      language={language}
      genericAirfield={true}
      alternateLanguages={alternateLanguages}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      defaultBannerImage={defaultBannerImage}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={universalProps}
      footerProps={footerProps}
      sideBarProps={universalProps}
      flightSearchErrorMessages={errorMessages}
      universalProps={universalProps}
      headerData={headerData}
      fsExcludeFlag={fsExcludeFlag}
      showFlightSearchWidget={false}
      widget={hasWidget ? widgetData : []}
      component={
        <React.Fragment>
          {
            fsExcludeFlag ? <div id="fs-exclude" style={{visibility: "hidden"}}>{fsExcludeFlag}</div> : null
          }
          {airfieldDetails ? 
            <React.Fragment>
              <H1
                fontSize="26px"
                fontWeight="500"
              >
                {heading} 
              </H1>
              <AirfieldInfo 
                localeAirfieldProps={localeAirfieldProps} 
                airfieldDetails={airfieldDetails}
                airfieldName={airfieldName}
                language={language}
              />
            </React.Fragment>
          : null }
          <LazyLoad
            height={200}
            once
            offset={-50}
          >
            <LoadableSingleMarkerMap 
              destinationLocationData={airfieldLocationData} 
              language={language}
            />
          </LazyLoad>
          <Para
            fontSize="14px"
            fontWeight="400"
            fontColor='rgb(70, 52, 78)'
          >
            {contactUsParagraph}
          </Para>
          <p style={{textAlign: 'center'}}>
            <InternalLink 
              path={`/${buttonURL}`} 
              language={language}
              linkComponent={
                <Button
                  fontColor="white"
                  marginBottom='1em'
                  padding='13px 40px'
                  fontSize='14px'
                  fontWeight='500'
                  text={localeAirfieldProps.button}
                />
              }
            />
          </p>
        </React.Fragment>
      }
    />
  );
};

GenericAirfield.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}

export default GenericAirfield

export const query = graphql`
  query airfieldDataQuery {
    allPrismicPropertiesAirfield {
      edges {
        node {
          ...PrismicPropertiesAirfield
        }
      }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          lang
          data {
            phone_local
            localised_phone {
              country_code
              phone_number
            }
          }
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
  }
`;
